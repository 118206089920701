// headers

/* playfair-display-700normal - latin */
@font-face {
    font-family: "Playfair Display";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
        local("Playfair Display Bold "), local("Playfair Display-Bold"),
        url("../fonts/playfair-display-latin-700.woff2") format("woff2"),
        
        url("../fonts/playfair-display-latin-700.woff") format("woff"); /* Modern Browsers */
}

// body

/* source-sans-pro-400normal - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
        local("Source Sans Pro Regular normal"),
        local("Source Sans Pro-Regularnormal"),
        url("../fonts/source-sans-pro-400.woff2") format("woff2"),
        url("../fonts/source-sans-pro-400.woff")
        format("woff"); /* Modern Browsers */
}

/* source-sans-pro-400italic - latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src:
        local("Source Sans Pro Regular italic"),
        local("Source Sans Pro-Regularitalic"),
        url("../fonts/source-sans-pro-400italic.woff2") format("woff2"),
        
        url("../fonts/source-sans-pro-400italic.woff") format("woff"); /* Modern Browsers */
}
