$enable-flex: true;
$primary: #343a40;

@import './node_modules/bootstrap/scss/bootstrap'; // Bootstrap 4
@import 'variables';
@import './node_modules/font-awesome/scss/font-awesome';
@import 'typography';
@import './node_modules/slick-carousel/slick/slick';
@import './node_modules/slick-carousel/slick/slick-theme';

// Styles
body {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  margin-top: 70px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+Cgk8ZWxsaXBzZSBjeD0nNTAlJyBjeT0nNTAlJyByeD0nNTAlJyByeT0nNTAlJyBmaWxsLW9wYWNpdHk9JzAnIHN0cm9rZT0nI2NjYycgc3Ryb2tlLW9wYWNpdHk9JzAuMzEnIHN0cm9rZS13aWR0aD0nOCcvPgo8L3N2Zz4=');
}

.navbar-brand,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-serif;
  font-weight: 700;
  hyphens: auto;
}

// navbar
.bg-faded {
  background: rgba($gray-400, 0.8);
}

.navbar-brand {
  text-transform: uppercase;
}

// content
h1 {
  margin-bottom: 1.5rem;
}

a {
  &:hover {
    text-decoration: none;
  }
}

img,
source {
  @include img-fluid;
}

// werk detailseite
.detail {
  display: flex;
  flex-flow: row;
  justify-content: center;

  picture img {
    max-height: 77vh;
    margin: 1rem auto;

    @extend .img-thumbnail;
  }

  ul {
    align-self: flex-start;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    li {
      margin: 1em 0 0 1em;
    }

    img,
    source {
      border-radius: 50%;

      @extend .img-thumbnail;

      height: 7em;
      width: 7em;
    }
  }

  span {
    @include sr-only;
  }
}

// werk karte
.card {
  margin-bottom: 1rem;
  background-color: #f1f1f1;
  background-image: $pattern;
  max-width: 30vh;
  position: relative;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, opacity;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: scale(1.08, 1.08);
    box-shadow: 0 15px 45px -5px rgb(10 16 34 / 15%);
    z-index: 1;

    &::after {
      opacity: 1;
    }

    // .card-body {
    //     -webkit-backface-visibility: hidden;
    //
    //     filter: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter id="gaussian_blur"><feGaussianBlur in="SourceGraphic" stdDeviation="0" /></filter></defs></svg>#gaussian_blur');
    // }
  }

  .list-group-item {
    background: transparent;
  }
}

// pager
.page-link {
  background-image: $pattern;
}

// carousel
.slider {
  img {
    max-height: 77vh;
    width: auto !important;
    margin: 0 auto;

    @extend .img-thumbnail;
  }

  .slick-prev::before {
    color: $gray-600;
  }

  .slick-next::before {
    color: $gray-600;
  }
}

// Über mich
.ueber-mich {
  section {
    display: flex;
    display: grid;
    justify-content: left;
    grid-template-columns: 50% 30%;
    grid-gap: 1rem;

    figure img,
    figure source {
      @extend .img-thumbnail;
    }
  }
}

footer {
  @extend footer;
  @include make-container;

  margin: 2rem auto;
}
