$enable-flex: true;
$enable-shadows: true;
$input-btn-line-height: 1.35;
$font-family-sans-serif: 'Source Sans Pro', system-ui, -apple-system, 'Segoe UI', roboto, 'Helvetica Neue', 'Noto Sans',
  'Liberation Sans', arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-serif: 'Playfair Display', georgia, 'Times New Roman', times, serif;
$primary: $gray-700;
$link-color: $gray-700;
$pattern: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23cccccc' fill-opacity='0.41' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
$body-bg: $gray-200;
$thumbnail-bg: #fff;
$card-columns-count: 4;
$list-group-bg: transparent;
$list-group-border-color: rgb(0, 0, 0, 5%);

// Slick carousel
$slick-font-path: '../fonts/';
$slick-loader-path: '../';
